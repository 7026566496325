import { render, staticRenderFns } from "./Logs.vue?vue&type=template&id=65345f05&scoped=true&"
import script from "./Logs.vue?vue&type=script&lang=js&"
export * from "./Logs.vue?vue&type=script&lang=js&"
import style0 from "./Logs.vue?vue&type=style&index=0&id=65345f05&prod&lang=scss&scoped=true&"
import style1 from "./Logs.vue?vue&type=style&index=1&id=65345f05&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65345f05",
  null
  
)

export default component.exports