<template>
  <div v-if="data && data.length" class="logs">
    <div ref="scroll">
      <div v-for="log in data" class="logs__log d-flex py-2">
        <vue-json-pretty
          :data="log"
          deep="4" />
<!--        <pre>{{ log }}</pre>-->
      </div>
      <div v-if="isLoadingMore" class="d-flex justify-content-center py-4">
        <b-spinner variant="primary" label="Spinning" />
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="isLoading" class="d-flex justify-content-center py-4">
      <b-spinner variant="primary" label="Spinning" />
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import {
    BSpinner,
} from 'bootstrap-vue'
import {usePage} from '@/use/page'
import {infiniteScroll} from "@/use/infiniteScroll"
import {fetchLogs} from "@/api/statistics"

export default {
  components: {
    VueJsonPretty,
    BSpinner,
  },
  setup(_, ctx) {

    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchLogs, _, ctx)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)





    return { isLoading,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll
    }
  }
}
</script>
<style lang="scss" scoped>
  .logs {

    &__log {
      border-bottom: 1px solid black;

      & p {
        max-width: 100%;
        word-wrap: break-word;
      }
    }
  }
</style>
<style lang="scss">
.vjs {

  &-value {
    word-break: break-word;
  }
}
</style>
